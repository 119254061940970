import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaginationCustomComponent} from './pagination-custom.component';
import {NgxPaginationModule} from "ngx-pagination";
import {AngularSvgIconModule} from "angular-svg-icon";


@NgModule({
    declarations: [
        PaginationCustomComponent
    ],
    exports: [
        PaginationCustomComponent
    ],
    imports: [
        CommonModule,
        NgxPaginationModule,
        AngularSvgIconModule.forRoot(),
    ]
})
export class PaginationCustomModule {
}
